import { FocusableIframe, Metadata } from "atoms";
import { graphql } from "gatsby";
import PropTypes from "prop-types";
import React from "react";

import TextPromo from "../text-promo/text-promo";
import * as styles from "./panorama.module.scss";

const Panorama = ({ panoramaTitle, caption, sourceUrl, metadata, text }) => {
  return (
    <section className={styles.panorama}>
      <div className="container-fluid" data-testid="panorama">
        <TextPromo
          summary={text}
          summaryTextAlign={"center"}
          title={panoramaTitle}
          withComponentSpacing={false}
        />
        <FocusableIframe
          caption={caption}
          height="75vh"
          src={sourceUrl}
          title={panoramaTitle}
        />
        <Metadata {...metadata} noLine={!caption} />
      </div>
    </section>
  );
};

Panorama.propTypes = {
  caption: PropTypes.string,
  panoramaTitle: PropTypes.string.isRequired,
  sourceUrl: PropTypes.string.isRequired,
  ...Metadata.PropType,
};

/**
 * The GraphQL fragment for retrieving Panorama data.
 * So long as this is exported with a matching name, Gatsby can make use of it.
 */
const PanoramaFragment = graphql`
  fragment PanoramaFragment on CraftAPI_componentList_panorama_BlockType {
    caption
    id
    sourceUrl
    objectName
    objectNumber
    objectTitle
    panoramaTitle
    displayTitle
    date
    linkField
    copyright
    creditLine
    subjectMedium
    text
  }
`;

/**
 * Converts the provided panorama data into a panorama
 *
 * @param panoramaData  the GraphQL response data
 * @returns             the panorama
 */
const convert = (panoramaData) => {
  const metadata = {
    caption: panoramaData.caption,
    objectName: panoramaData.objectName,
    objectNumber: panoramaData.objectNumber,
    objectTitle: panoramaData.objectTitle,
    displayTitle: panoramaData.displayTitle,
    linkField: panoramaData.linkField,
    copyright: panoramaData.copyright,
    creditLine: panoramaData.creditLine,
    subjectMedium: panoramaData.subjectMedium,
    type: "component",
  };

  return (
    <Panorama key={panoramaData.id} metadata={metadata} {...panoramaData} />
  );
};

export { convert, Panorama as default, PanoramaFragment };
