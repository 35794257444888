import { graphql } from "gatsby";
import {
  ChapterHero,
  ExhibitHero,
  PillarHero,
  ScatteredHero,
  StoryHero,
  TextHero,
} from "molecules";
import React from "react";

const VideoHeroFragment = graphql`
  fragment VideoHeroFragment on CraftAPI_heroComponent_videoPromo_BlockType {
    coverImage {
      ... on CraftAPI_image_Asset {
        title
        url
        landscape: imageFile {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, aspectRatio: 2)
          }
        }
        portrait: imageFile {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED)
          }
        }
        altText
      }
    }
    byline
    headline
    src
  }
`;

const convertVideoPromo = (videoPromoData) => ({
  coverImage: videoPromoData?.coverImage[0],
  headline: videoPromoData?.headline,
  caption: videoPromoData?.byline,
  src: videoPromoData?.src,
});

const convertProps = (componentData, heroType) => {
  const props = {
    audioAsset: componentData.audioAsset?.[0],
    audioClosedCaption: componentData.audioClosedCaption?.[0]?.url,
    backgroundImageUrl: componentData.backgroundImage?.[0]?.url,
    fontColor: componentData.fontColor,
    header: componentData.header,
    headingColor: componentData.headingColor,
    imageObjectFit:
      heroType === "story"
        ? componentData.parent?.coverImage
          ? componentData.parent?.coverImageCropStyle
          : componentData.coverImageCropStyle
        : componentData.coverImageCropStyle,
    imageObjectPosition:
      heroType === "story"
        ? componentData.parent?.coverImage
          ? componentData.parent?.coverImagePositionOverride
          : componentData.coverImagePositionOverride
        : componentData.coverImagePositionOverride,
    key: `hero-${componentData.id}`,
    subtitle: componentData.subtitle,
    shortDescription: componentData.shortDescription,
    longDescription: componentData.longDescription,
    title:
      heroType === "story"
        ? componentData.parent?.coverImage
          ? componentData.parent?.title
          : componentData.title
        : componentData.title,
    formattedTitleOverride: componentData.formattedTitleOverride,
    fontType: componentData.fontType,
    titleSize: componentData.titleSize,
    titleColor: componentData.titleColor,
    presentationType: componentData.presentationType,
    titleDate: componentData.titleDate,
    theme: componentData.theme,
    pageType: componentData.pageType,
    storyType: componentData.parent?.storyType || componentData.storyType,
    tags: componentData.tags,
    heroIcon: componentData.heroIcon,
    heroBackgroundAlignment: componentData.heroBackgroundAlignment,
  };

  const coverImage =
    heroType === "story"
      ? componentData.parent?.coverImage || componentData.coverImage
      : componentData.coverImage;

  if (coverImage?.length > 1) {
    props.images = coverImage;
  } else if (coverImage?.length === 1) {
    props.image = {
      ...coverImage?.[0], // pick up metadata fields
      imageFile: coverImage?.[0]?.imageFile, // add actual image field with specific image layout
    };
  }
  return props;
};

const convertChapterHero = (componentData) => (
  <ChapterHero {...convertProps(componentData)} />
);

const convertExhibitHero = (componentData) => (
  <ExhibitHero {...convertProps(componentData)} />
);

const convertTextHero = (componentData) => (
  <TextHero {...convertProps(componentData)} />
);

const convertPillarHero = (componentData) => (
  <PillarHero
    {...convertProps(componentData)}
    videoPromo={convertVideoPromo(componentData.heroComponent?.[0])}
  />
);

const convertStoryHero = (componentData) => (
  <StoryHero
    {...convertProps(componentData, "story")}
    activeNavItemId={componentData.activeNavItemId}
    heroSubtype={componentData.heroSubtype}
    navItems={componentData.navItems}
  />
);

const convertScatteredHero = (componentData) => (
  <ScatteredHero {...convertProps(componentData)} />
);

const heroConverters = {
  chapter: convertChapterHero,
  exhibit: convertExhibitHero,
  pillar: convertPillarHero,
  story: convertStoryHero,
  text: convertTextHero,
  scattered: convertScatteredHero,
};

/**
 * Converts the provided hero component data into one or more React hero components
 *
 * @param componentData the GraphQL response data
 * @returns {*[]}       the React hero components
 */
const convert = (componentData) => {
  if (componentData === undefined) return;
  // Despite configuring a single element in Craft, the GraphQL response can return an array
  const elements = Array.isArray(componentData)
    ? componentData
    : [componentData];

  return elements.map((data) => heroConverters[data.heroType]?.(data));
};

export { convert, convertProps, convertVideoPromo, VideoHeroFragment };
