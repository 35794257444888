import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";

import * as styles from "./tag.module.scss";

const Tag = ({ color, icon, inList, interactive, text }) => {
  const Wrapper = inList ? "li" : "p";
  return (
    <Wrapper
      className={classNames(styles.tag, styles[color], {
        [styles.interactive]: interactive,
      })}
      data-testid={`${text.toLowerCase().replace(" ", "-")}-tag`}
    >
      {icon && <i className={icon} />}
      {text}
    </Wrapper>
  );
};

Tag.Colors = {
  Blue: "blue",
  Red: "red",
  OffBlack: "offBlack",
  dark: "black",
  light: "white",
};

Tag.propTypes = {
  color: PropTypes.oneOf(Tag.Colors),
  icon: PropTypes.string,
  inList: PropTypes.bool,
  interactive: PropTypes.bool,
  text: PropTypes.string.isRequired,
};

Tag.defaultProps = {
  color: Tag.Colors.OffBlack,
};

export default Tag;
