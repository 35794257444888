import { NMAAHCPropTypes, Theme, useScrollObserver } from "assets";
import { ActionButton, FormattedText } from "atoms";
import classNames from "classnames";
import { graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { TextPromo } from "molecules";
import PropTypes from "prop-types";
import React, { useRef, useState } from "react";

import * as styles from "./showstopper.module.scss";

const Showstopper = ({ breakpoints, description, title, video }) => {
  const ref = useRef();
  const videoRef = useRef(null);
  const scrolledClasses = useScrollObserver(ref, { startThreshold: 0.8 });
  const [isTransitioning, setIsTransitioning] = useState(false);
  const [expandVideo, setExpandVideo] = useState(false);
  const [stepNumber, setStepNumber] = useState(0);
  const [controlPanelStyle, setControlPanelStyle] = useState({});
  const [nextStepDelay, setNextStepDelay] = useState(0);

  const stepIndex = () => {
    return stepNumber - 1 < 0 ? 0 : stepNumber - 1;
  };

  const videoFileFormats = [".mov", ".mp4", ".wmv", ".avi", ".flv"],
    hasVideo = videoFileFormats.some((format) => video.url.includes(format));

  const wrapperClasses = classNames(
    "container-fluid",
    styles.showstopper,
    scrolledClasses,
    {
      [styles.expanded]: expandVideo,
      [styles.transitioning]: isTransitioning,
      [styles.noVideo]: !hasVideo,
    }
  );

  const percentageProgress = () => {
    const currentStep = isTransitioning ? stepNumber + 1 : stepNumber;
    return `${(currentStep / breakpoints?.length) * 100}%`;
  };

  const endOfShow = () => stepNumber === breakpoints?.length;

  const updateShowstopper = async (action = "forward") => {
    const goForward = action === "forward",
      restart = action === "restart";

    if (endOfShow() && goForward) {
      return false;
    }

    if (restart) {
      setStepNumber(0);
      if (hasVideo) {
        videoRef.current.currentTime = 0;
      }
      videoRef.current?.load();
    }

    const currentStep = restart ? 0 : stepNumber;
    const wait = (ms) => new Promise((res) => setTimeout(res, ms));

    const nextVideoPlayTime = () => {
      const BPSeconds = breakpoints.map((bp) => bp.duration * 1000),
        nextTime = BPSeconds[currentStep],
        elapsedTime = BPSeconds[currentStep - 1] || 0;

      setNextStepDelay(nextTime - elapsedTime);
      return nextTime - elapsedTime;
    };

    if (stepNumber === 0) {
      setControlPanelStyle({
        animationDelay: `${nextVideoPlayTime() / 1000}s`,
      });
    }

    videoRef.current?.play();
    setIsTransitioning(true);
    await wait(nextVideoPlayTime());
    videoRef.current?.pause();
    setStepNumber(currentStep + 1);
    setIsTransitioning(false);
    await wait(250);

    ref.current
      .querySelector(
        `#showstopper-${
          currentStep + 1 === breakpoints?.length ? "restart" : "next"
        }`
      )
      .focus();
  };

  return (
    <div className={wrapperClasses} data-testid="showstopper" ref={ref}>
      <TextPromo
        summary={description}
        title={title}
        withComponentSpacing={false}
      />
      <div className={styles.interactiveContainer}>
        <div className={styles.videoContainer}>
          {hasVideo && (
            <video data-testid="video" ref={videoRef} src={video?.url} />
          )}
          <div className={styles.startButtonContainer}>
            <ActionButton
              ariaDisabled={isTransitioning}
              onClick={() => {
                setExpandVideo(true), updateShowstopper();
              }}
              screenReaderText={`to ${title}`}
              text={"Interact to Explore"}
            />
          </div>
        </div>
        <div aria-live="polite" className={styles.stepDescription}>
          {stepNumber > 0 && (
            <div className={styles.stepDescriptionInner}>
              <h3 className={styles.stepDescriptionTitle}>
                {breakpoints[stepIndex()].breakpointTitle}
              </h3>
              {breakpoints[stepIndex()].image &&
                breakpoints[stepIndex()].image.map((image, i) => (
                  <figure className={styles.imageFigure} key={i}>
                    <GatsbyImage
                      alt={image.altText || ""}
                      image={getImage(
                        image.imageFile.childImageSharp.gatsbyImageData
                      )}
                    />
                    {image.creditLine && (
                      <figcaption className={styles.imageCaption}>
                        {image.creditLine}
                      </figcaption>
                    )}
                  </figure>
                ))}
              <FormattedText
                text={breakpoints[stepIndex()].description}
                theme={Theme.Dark}
              />
            </div>
          )}
        </div>
        <div
          className={styles.controlPanel}
          id="showstopper-control-panel"
          style={controlPanelStyle}
        >
          <ActionButton
            ariaDisabled={isTransitioning || stepNumber === 1}
            icon={"undo"}
            id="showstopper-restart"
            onClick={() => updateShowstopper("restart")}
            screenReaderText={`steps for ${title}`}
            text="Restart"
          />

          <div
            aria-label={`On Step ${stepNumber} of ${breakpoints?.length} for ${title}`}
            className={styles.progressBar}
          >
            <div
              className={styles.progressBarInner}
              style={{
                width: percentageProgress(),
                transitionDuration: `${nextStepDelay / 1000}s`,
              }}
            />
          </div>

          <ActionButton
            ariaDisabled={isTransitioning || endOfShow()}
            icon={"arrow-right"}
            id="showstopper-next"
            onClick={() => updateShowstopper()}
            screenReaderText={`step in ${title}`}
            text="Next"
          />
        </div>
      </div>
    </div>
  );
};

Showstopper.propTypes = {
  breakpoints: PropTypes.arrayOf(
    PropTypes.shape({
      breakpointTitle: PropTypes.string,
      description: PropTypes.string,
      duration: PropTypes.number,
      image: PropTypes.arrayOf(NMAAHCPropTypes.Image),
    })
  ),
  description: PropTypes.string,
  title: PropTypes.string.isRequired,
  video: PropTypes.shape({
    url: PropTypes.string,
  }),
};

/**
 * The GraphQL fragment for retrieving Showstopper data.
 * So long as this is exported with a matching name, Gatsby can make use of it.
 */
const ShowstopperFragment = graphql`
  fragment ShowstopperFragment on CraftAPI_componentList_showstopper_BlockType {
    id
    breakpoints {
      ... on CraftAPI_breakpoints_BlockType {
        id
        breakpointTitle
        description
        duration
        image {
          ... on CraftAPI_image_Asset {
            altText
            creditLine
            imageFile {
              childImageSharp {
                gatsbyImageData
              }
            }
            title
            url
          }
        }
      }
    }
    description
    showstopperTitle
    video {
      url
    }
  }
`;

/**
 * Converts the provided showstopper data into a showstopper
 *
 * @param showstopperData  the GraphQL response data
 * @returns             the showstopper
 */
const convert = (showstopperData) => {
  return (
    <Showstopper
      breakpoints={showstopperData.breakpoints}
      description={showstopperData.description}
      key={showstopperData.id}
      title={showstopperData.showstopperTitle}
      video={showstopperData.video?.[0]}
    />
  );
};

export { convert, Showstopper as default, ShowstopperFragment };
