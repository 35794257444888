import { useLocation } from "@reach/router";
import { GoToComponent, NMAAHCPropTypes, useScrollObserver } from "assets";
import { BackgroundImageWrapper, Scroller } from "atoms";
import classNames from "classnames";
import useEmblaCarousel from "embla-carousel-react";
import { WheelGesturesPlugin } from "embla-carousel-wheel-gestures";
import { graphql } from "gatsby";
import { getImage } from "gatsby-plugin-image";
import { ExpandableImage, TextPromo } from "molecules";
import PropTypes from "prop-types";
import queryString from "query-string";
import React, { useCallback, useRef } from "react";

import * as styles from "./gallery.module.scss";

/** The height (in rem units) for all images **/
const CONSTRAINED_IMAGE_HEIGHT = parseFloat(styles.itemHeight);

/** The aspect ratio cutoff for tall images that should span multiple rows **/
const TWO_ROW_ASPECT_RATIO = 0.8;

/**
 * Returns the aspect ratio of the provided image (width / height)
 *
 * @param image       the image to check
 * @returns {number}  the aspect ratio
 */
const getAspectRatio = (image) => {
  return (
    getImage(image?.image?.[0]?.imageFile)?.width /
    getImage(image?.image?.[0]?.imageFile)?.height
  );
};

/**
 * Returns whether the provided image is considered "tall" based on the image's
 * aspect ratio
 *
 * @param image       the image to check
 * @returns {boolean} whether the image is considered tall
 */
const isTallImage = (image) => getAspectRatio(image) <= TWO_ROW_ASPECT_RATIO;

/**
 * Given an image, returns the fixed width for the image based on the constrained
 * height for all images and this image's aspect ratio
 *
 * @param image      the image to compute
 * @param twoRows    if the gallery has two rows
 * @returns {number} the width in rem units
 */
const getFixedWidth = (image, twoRows) => {
  // If the gallery is two rows and the image is tall, we want the size to be doubled so it spans both rows
  const heightCoefficient = twoRows && isTallImage(image) ? 2.25 : 1;

  // changes the image height based on wether or now there are two rows
  const heightToggle = twoRows
    ? CONSTRAINED_IMAGE_HEIGHT / 1.5
    : CONSTRAINED_IMAGE_HEIGHT;

  return heightToggle * heightCoefficient * getAspectRatio(image);
};

/**
 * Returns the CSS styles for a given set of images and a slide index
 *
 * @param groups  the groups of images
 * @param i       the slide index
 * @param twoRows if the gallery has two rows
 * @returns       the CSS styles
 */
const emblaSlideStyle = (groups, i, twoRows) => {
  // Determine the width of the widest image in this group
  const maxWidth = Math.max(
    ...groups[i].map((img) => getFixedWidth(img, twoRows))
  );

  const style = {
    flex: `0 0 ${maxWidth.toFixed(3)}rem`,
  };

  if (i === 0) {
    // Compute the width of all the slides
    const totalGroupWidth = groups.reduce((prev, group) => {
      return (
        prev + Math.max(...group.map((img) => getFixedWidth(img, twoRows)))
      );
    }, 0);

    return {
      // Embla seems to translate away any attempts to center-justify our slides
      // We get around this by applying left padding to the first slide
      paddingLeft: `max(2rem, calc((100% - (${totalGroupWidth}rem + (${groups.length} - 1) * ${styles.galleryItemPadding})) / 2))`,
      ...style,
    };
  } else {
    return style;
  }
};

/**
 * Returns whether the provided image can fit as the second image in the current
 * column
 *
 * @param column      the current column of images
 * @param image       the image to check
 * @param twoRows     if the gallery has two rows
 * @returns {boolean} whether the provided image can fit in the current column
 */
const canFitSecondImage = (column, image, twoRows) => {
  // If we don't have two rows, we can never fit a second image
  if (!twoRows) return false;
  // Can't fit a second image if there are already two
  if (column?.length === 2) return false;
  // Can't fit a second image if the first image is tall
  if (column?.length && isTallImage(column[0])) return false;
  // If the provided image is tall, it should be in a new column

  return !isTallImage(image);
};

/**
 * Converts the set of images to Embla slides. This will align images into two
 * rows if specified and adjust the slide styles for a uniform height.
 *
 * @param galleryId     the id of this gallery
 * @param expandedSlide the optional default expanded slide number
 * @param media         the images to convert into slides
 * @param twoRows       whether or not two rows should be usd
 * @returns              an array of Embla slide elements
 */
const imagesToSlides = (
  galleryId,
  expandedSlide,
  media,
  twoRows,
  passiveGallery
) => {
  const groupedImages = [];
  media.forEach((img, i) => {
    // Check to see if any of the existing columns/groups can fit this image
    const openGroup = groupedImages.find((group) =>
      canFitSecondImage(group, img, twoRows)
    );
    if (openGroup) {
      // Found a group that has room for another image, stick it here
      openGroup.push({ imageIndex: i, ...img });
    } else {
      // Max number of images in all groups, start a new group
      groupedImages.push([{ imageIndex: i, ...img }]);
    }
  });

  return groupedImages.map((group, i) => {
    return (
      <div
        className={classNames("embla__slide", styles.galleryItem, {
          [styles.captionless]:
            twoRows && group.length === 2 && !group[0].caption,
        })}
        data-testid="slide"
        key={`slide-${i}`}
        style={emblaSlideStyle(groupedImages, i, twoRows)}
      >
        {group.map((image) => (
          <ExpandableImage
            audioAsset={image?.audio?.[0]}
            defaultExpand={expandedSlide === String(i + 1)}
            galleryMedia={media}
            galleryStartIndex={image?.imageIndex}
            image={image?.image?.[0]}
            imageCaption={image?.imageCaption}
            imageClassName={styles.galleryItemImg}
            imageWrapperClassName={
              twoRows && isTallImage(image)
                ? styles.doubleItem
                : twoRows
                  ? styles.galleryItemWrapperTwoRows
                  : styles.galleryItemWrapper
            }
            key={`image-${image?.imageIndex}}`}
            layout="fixed"
            modalCaptionOnly={passiveGallery}
            modalText={image?.imageDescription}
            onClose={() => {
              GoToComponent.setGoToComponent(galleryId);
            }}
            onImageReveal={(slideIndex) => {
              GoToComponent.setGoToComponent(galleryId, {
                slide: slideIndex + 1,
              });
            }}
            slideIndex={i}
            title={image?.video?.[0] && image?.imageTitle}
            video={image?.video?.[0]}
          />
        ))}
      </div>
    );
  });
};

const Gallery = ({
  backgroundCover,
  backgroundImageUrl,
  fontColor,
  galleryId,
  media,
  twoRows,
  title,
  subtitle,
  subtitleTextAlignment,
  passiveGallery,
}) => {
  const ref = useRef();
  const containerRef = useRef();
  const scrolledClasses = useScrollObserver(ref);

  const options = {
    dragFree: true,
    containScroll: "trimSnaps",
  };

  const [emblaRef, emblaApi] = useEmblaCarousel(options, [
    WheelGesturesPlugin(),
  ]);

  const scrollPrev = useCallback(() => {
    if (emblaApi) emblaApi.scrollPrev();
  }, [emblaApi]);

  const scrollNext = useCallback(() => {
    if (emblaApi) emblaApi.scrollNext();
  }, [emblaApi]);

  const location = useLocation();
  const defaultSlide = GoToComponent.isGoToComponent(galleryId, location)
    ? queryString.parse(location.search)?.slide
    : undefined;

  const className = classNames(styles.gallery, scrolledClasses);
  const prevClass = classNames("embla__prev", styles.navButton);
  const nextClass = classNames("embla__next", styles.navButton);

  return (
    <BackgroundImageWrapper
      backgroundCover={backgroundCover}
      backgroundImageUrl={backgroundImageUrl}
      className={className}
      fontColor={fontColor}
      id={`component-${galleryId}`}
      ref={ref}
      fullWidthComponent
    >
      {title && (
        <div className={`container-fluid ${styles.textContainer}`}>
          <TextPromo
            summary={subtitle}
            summaryTextAlign={subtitleTextAlignment}
            title={title}
            withComponentSpacing={false}
          />
        </div>
      )}
      <div className="row" data-testid="gallery">
        <div className="col-xs">
          {passiveGallery && (
            <div className={styles.btnContainer}>
              <button
                aria-label="Previous Slide"
                className={prevClass}
                onClick={scrollPrev}
              >
                <i aria-hidden="true" className="icon-arrow-back-bold" />
              </button>
              <button
                aria-label="Next Slide"
                className={nextClass}
                onClick={scrollNext}
              >
                <i aria-hidden="true" className="icon-arrow-forward-bold" />
              </button>
            </div>
          )}
          <div className="embla">
            <div
              className="embla__viewport"
              ref={emblaRef}
              style={{ paddingTop: "10px", paddingBottom: "10px" }} // Room for the focus border
            >
              <div
                className={`embla__container ${styles.galleryContainer}`}
                ref={containerRef}
              >
                {imagesToSlides(
                  galleryId,
                  defaultSlide,
                  media,
                  twoRows,
                  passiveGallery
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.scrollContainer}>
        <Scroller
          embla={emblaApi}
          ref={containerRef}
          scrollBarColor={fontColor}
        />
      </div>
    </BackgroundImageWrapper>
  );
};

Gallery.propTypes = {
  backgroundCover: PropTypes.bool,
  backgroundImageUrl: PropTypes.string,
  fontColor: PropTypes.string,
  galleryId: PropTypes.string,
  media: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.arrayOf(NMAAHCPropTypes.Image),
    })
  ).isRequired,
  passiveGallery: PropTypes.bool,
  subtitle: PropTypes.string,
  subtitleTextAlignment: PropTypes.oneOf(["center", "left"]),
  title: PropTypes.string,
  twoRows: PropTypes.bool,
};

Gallery.defaultProps = {
  passiveGallery: false,
  twoRows: false,
};

const GalleryFragment = graphql`
  fragment GalleryFragment on CraftAPI_componentList_gallery_BlockType {
    id
    backgroundCoverToggle
    backgroundImage {
      ... on CraftAPI_image_Asset {
        url
      }
    }
    fontColor
    galleryTitle
    gallerySubtitle
    subtitleTextAlignment
    galleryImages {
      ... on CraftAPI_galleryImages_BlockType {
        audio {
          ...AudioAssetFragment
        }
        imageCaption
        imageDescription
        image {
          ... on CraftAPI_image_Asset {
            ...ImageMetadataFragment
          }
        }
        imageTitle
        video {
          embeddedAsset {
            ... on CraftAPI_EmbeddedAsset {
              url
              title
              code
            }
          }
        }
      }
    }
    twoRows
  }
`;

const convert = (galleryData) => {
  return (
    <Gallery
      backgroundCover={galleryData.backgroundCoverToggle}
      backgroundImageUrl={galleryData.backgroundImage?.[0]?.url}
      fontColor={galleryData.fontColor}
      galleryId={galleryData.id}
      key={galleryData.id}
      media={galleryData.galleryImages}
      subtitle={galleryData.gallerySubtitle}
      subtitleTextAlignment={galleryData.subtitleTextAlignment}
      title={galleryData.galleryTitle}
      twoRows={galleryData.twoRows}
    />
  );
};

export {
  canFitSecondImage,
  CONSTRAINED_IMAGE_HEIGHT,
  convert,
  Gallery as default,
  GalleryFragment,
  getAspectRatio,
  getFixedWidth,
  isTallImage,
  TWO_ROW_ASPECT_RATIO,
};
