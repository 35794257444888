import { Theme } from "assets";
import {
  BackgroundImageWrapper,
  FormattedText,
  ThematicPillarCard,
} from "atoms";
import classNames from "classnames";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";

import * as styles from "./thematic-card-listing.module.scss";

const randomizeOrderOfCards = (cards) => {
  let currentIndex = cards.length,
    randomIndex;
  while (currentIndex != 0) {
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;
    [cards[currentIndex], cards[randomIndex]] = [
      cards[randomIndex],
      cards[currentIndex],
    ];
  }
  return cards;
};

const ThematicCardListing = (props) => {
  const { backgroundCover, backgroundImageUrl, fontColor, shuffle, pillars } =
    props;
  const [cards, setCards] = useState([]);
  const _pillars = [...pillars];
  useEffect(() => {
    if (shuffle) {
      randomizeOrderOfCards(_pillars);
    }
    setCards(_pillars);
  }, []);

  const className = classNames(
    "container-fluid",
    styles.cardsListingCtn,
    Theme.addClass(Theme.Dark, true, true)
  );

  const listingClass = classNames(styles.cardsListing, {
    [styles.fourCardLayout]: cards.length === 4,
  });

  return (
    <BackgroundImageWrapper
      backgroundCover={backgroundCover}
      backgroundImageUrl={backgroundImageUrl}
      className={className}
      fontColor={fontColor}
    >
      <div className="row center-xs">
        <div className={classNames("col-xs-12 col-sm-10", styles.titleCtn)}>
          <FormattedText
            outerElement={<h2 />}
            text={"Explore Exhibition Themes"}
            deepLink
          />
          {cards.length && (
            <span>
              <i className={`${styles.icon} icon-stacked-box`} />
              {cards.length} Themes
            </span>
          )}
        </div>
      </div>
      <div className={listingClass} data-testid={"thematic-card-listing"}>
        {cards.map((card) => (
          <ThematicPillarCard
            buttonText={card.buttonText}
            coverImage={card.coverImage?.[0]}
            formattedTitleOverride={card.formattedTitleOverride}
            imageAlt={card.coverImage[0].altText}
            key={card.id}
            pageLink={card.uri}
            title={card.title}
          />
        ))}
      </div>
    </BackgroundImageWrapper>
  );
};

ThematicCardListing.propTypes = {
  backgroundCover: PropTypes.bool,
  backgroundImageUrl: PropTypes.string,
  fontColor: PropTypes.string,
  id: PropTypes.string,
  pillars: PropTypes.arrayOf(
    PropTypes.shape({
      buttonText: PropTypes.string,
      coverImage: PropTypes.arrayOf(PropTypes.shape({})),
      id: PropTypes.string,
      shortDescription: PropTypes.string,
      title: PropTypes.string,
    })
  ),
  shuffle: PropTypes.bool,
};

ThematicCardListing.defaultProps = {
  fontColor: "white",
};

const convert = (pillarData) => {
  return (
    <ThematicCardListing
      backgroundCover={pillarData?.backgroundCoverToggle}
      backgroundImageUrl={pillarData?.backgroundImage?.[0]?.url}
      fontColor={pillarData?.fontColor}
      key={pillarData.id}
      {...pillarData}
    />
  );
};

export { convert, ThematicCardListing as default };
