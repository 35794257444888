// extracted by mini-css-extract-plugin
export var btnContainer = "gallery-module--btnContainer--UdCIz";
export var captionless = "gallery-module--captionless--o7kzP";
export var doubleItem = "gallery-module--doubleItem--XSmqR";
export var gallery = "gallery-module--gallery--Apozd";
export var galleryContainer = "gallery-module--galleryContainer--ablkO";
export var galleryItem = "gallery-module--galleryItem--RjI8-";
export var galleryItemImg = "gallery-module--galleryItemImg--41bX1";
export var galleryItemPadding = "2rem";
export var galleryItemWrapper = "gallery-module--galleryItemWrapper--LA-6Y";
export var galleryItemWrapperTwoRows = "gallery-module--galleryItemWrapperTwoRows--iUYv0";
export var hasSubtitle = "gallery-module--hasSubtitle--Degdt";
export var itemHeight = "19.6875rem";
export var navButton = "gallery-module--navButton--UBOhg";
export var scrollContainer = "gallery-module--scrollContainer--sLhjs";
export var textContainer = "gallery-module--textContainer--Jv2Zy";